import {BToast} from "bootstrap-vue";

export const clipboardHelper = {
    copy
};

async function copy(text, message = 'Skopiowano do schowka') {
    try {
        await navigator.clipboard.writeText(text);

        let bootStrapToaster = new BToast();
        bootStrapToaster.$bvToast.toast(message, {
            title: 'Sukces!',
            variant: 'success',
            solid: true
        });
    } catch (error) {
        console.log('cannot copy')
    }
}