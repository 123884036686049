<template>
  <b-modal
      v-model="visibleModal"
      :title="title"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">
    <div class="form-group text-center">
      <p>W celu potwierdzenia zmian przepisz poniższy kod:</p>
      <p><b>{{ code }}</b></p>
      <input v-model="form.inputCode" type="text" class="form-control" />
    </div>

    <div class="text-right">
      <b-button @click="runCallback" variant="danger">{{ buttonMessage }}</b-button>
      <b-button class="ml-1" variant="secondary" @click="hideModal">{{  $t('message.cancel') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import {codeGenerator} from "../helpers/code-generator";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      visibleModal: false,
      title: "",
      message: "",
      buttonMessage: "",
      callback: null,

      code: "",
      form: {
        inputCode: ""
      }
    }
  },

  methods: {
    openModal(title, message, buttonMessage, callback) {
      this.code = codeGenerator.generateRandomCode(6)
      this.title = title
      this.message = message
      this.buttonMessage = buttonMessage
      this.callback = callback
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.title = ""
      this.message = ""
      this.buttonMessage = ""
      this.callback = null
      this.code = ""
      this.form.inputCode = ""
    },

    async runCallback() {
      if (this.code !== this.form.inputCode) {
        await Swal.fire("Błąd!", "Kod przepisany nie zgadza się!", "error");
        return
      }

      await this.callback()
    }
  }
}
</script>