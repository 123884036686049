<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
import axios from "axios";
import {jsonUtil} from "@/helpers/json-util";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {errorCatcher} from "@/helpers/error-catcher";
import WarehouseCarrierTable from "@/components/warehouse/warehouse-carrier-table.vue";
import {clipboardHelper} from "@/helpers/clipboard-helper";

export default {
  computed: {
    clipboardHelper() {
      return clipboardHelper
    },

    jsonUtil() {
      return jsonUtil
    }
  },

  components: {
    WarehouseCarrierTable,
    Layout,
    PageHeader,
    ckeditor: CKEditor.component
  },

  data() {
    return {
      submitted: false,

      warehouse: null,
      parsers: [],
      industry: null,
      market: null,

      deliveryUnits: null,
      deliveryUnitsMap: null,

      editor: ClassicEditor,

      modals: {

        editElementInTable: {

          item: null,
          title: "",
          note: null,
          visibleModal: false,
          element: "",
          elementName: "",
          type: ""

        }

      },

      carrierMappings: {},
      refs: {},
      reloadTables: false
    };
  },

  methods: {

    setRef(refName) {
      return (ref) => {
        if (!ref) {
          return
        }

        this.$set(this.refs, refName, ref);
      };
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('admin.warehouse.show.title', {'warehouse_name': this.warehouse ? this.warehouse.name : ''}),
          active: true
        }
      ]
    },

    async loadWarehouse() {
      try {
        const id = this.$route.params.id;
        if (!id) {
          await Swal.fire("", "Nie znaleziono takiej hurtowni!", "error");
          return;
        }

        const { data } = await axios.get(`/warehouse/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.warehouse = data
        await this.loadCarrierMappings()
        await this.loadParsers()
        await this.loadIndustry()
        await this.loadMarket()
      } catch (error) {
        console.log(error)
      }
    },

    async loadCarrierMappings() {
      try {
        const { data } = await axios.get(`/warehouse/carrier-information/${this.warehouse.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.carrierMappings = data.carrierMappings
        this.reloadTables = !this.reloadTables
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadDeliveryUnits() {
      try {
        const result = await axios.get(`/delivery-unit`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.deliveryUnits = result.data.deliveryUnits
        this.deliveryUnitsMap = new Map(this.deliveryUnits.map((obj) => [obj.deliveryUnit, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getDeliveryUnit(unit) {
      if (!this.deliveryUnitsMap) {
        return null
      }

      return this.deliveryUnitsMap.get(unit) || null
    },

    async loadParsers() {
      try {
        const { data } = await axios.get(`/parser/tag/list/by/warehouse/${this.warehouse.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.parsers = data
      } catch (error) {
        console.log(error)
      }
    },

    async loadIndustry() {
      if (!this.warehouse.industryId) {
        return
      }

      try {
        const { data } = await axios.get(`/industry/${this.warehouse.industryId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.industry = data
      } catch (error) {
        console.log(error)
      }
    },

    async loadMarket() {
      if (!this.warehouse.marketId) {
        return
      }

      try {
        const { data } = await axios.get(`/market/${this.warehouse.marketId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.market = data
      } catch (error) {
        console.log(error)
      }
    },

    saveWarehouse(callback, edited) {
      this.submitted = true;

      const json = JSON.stringify((edited) ? this.modals.editElementInTable.item : this.warehouse);
      axios.post(`/warehouse`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        this.$bvToast.toast('Zaktualizowano hurtownie', {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.loadWarehouse()
        if (callback) {
          callback()
        }
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    hideEditElementInTableModal() {
      this.modals.editElementInTable.visibleModal = false
      this.modals.editElementInTable.item = null
      this.modals.editElementInTable.title = ""
      this.modals.editElementInTable.note = null
      this.modals.editElementInTable.element = ""
      this.modals.editElementInTable.elementName = ""
      this.modals.editElementInTable.type = ""
    },

    openEditElementInTableModal(item, element, elementName, type, title, note = null) {
      this.modals.editElementInTable.item = Object.assign({}, item)
      this.modals.editElementInTable.element = element
      this.modals.editElementInTable.title = title
      this.modals.editElementInTable.note = note
      this.modals.editElementInTable.elementName = elementName
      this.modals.editElementInTable.type = type
      this.modals.editElementInTable.visibleModal = true
    },

    shouldRenderCommitChanges() {
      if (this.refs["warehouse_carrier_table"] && this.refs["warehouse_carrier_table"].isShouldUpdate()) {
        return true
      } else if (this.refs["ecat_carrier_table"] && this.refs["ecat_carrier_table"].isShouldUpdate()) {
        return true
      } else if (this.refs["ecat_allegro_smart_carrier_table"] && this.refs["ecat_allegro_smart_carrier_table"].isShouldUpdate()) {
        return true
      } else if (this.refs["oversize_carrier_table"] && this.refs["oversize_carrier_table"].isShouldUpdate()) {
        return true
      }

      return false
    },

    async commitChanges() {
      if (this.refs["warehouse_carrier_table"]) {
        await this.refs["warehouse_carrier_table"].commitChanges()
      }

      if (this.refs["ecat_carrier_table"]) {
        await this.refs["ecat_carrier_table"].commitChanges()
      }

      if (this.refs["ecat_allegro_smart_carrier_table"]) {
        await this.refs["ecat_allegro_smart_carrier_table"].commitChanges()
      }

      if (this.refs["oversize_carrier_table"]) {
        await this.refs["oversize_carrier_table"].commitChanges()
      }

      this.$root.$emit("admin-reload-warehouse-carriers")
    }

  },

  async created() {
    try {
      await this.loadWarehouse()
      await this.loadDeliveryUnits()
    } catch (error) {
      console.log(error)
    }
  },

  mounted() {
    this.$root.$on("admin-reload-warehouse-carriers", async () => await this.loadCarrierMappings())
  },

  beforeDestroy() {
    this.$root.$off("admin-reload-warehouse-carriers");
  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('admin.warehouse.show.title', { 'warehouse_name': warehouse ? warehouse.name : '' })" :items="getItems()" />

    <div class="row">
      <div class="col-12">
        <div class="mb-2">
          <a @click="$router.go(-1)" class="btn btn-primary w-sm mb-2">
            {{ $t('page.back') }}
          </a>
        </div>
      </div>

      <template v-if="warehouse && market">
      <div class="col-12 col-lg-8">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover table-bordered">
                <tbody><tr>
                  <th>Cecha</th>
                  <th>Wartość</th>
                  <th>Akcje</th>
                </tr>

                <tr class="bg-danger text-white">
                  <td>Firma z umowy</td>
                  <td>{{ warehouse.contractCompany }}</td>
                  <td>
                    <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(warehouse, 'contractCompany', 'Firma z umowy', 'STRING', 'Zmiana firmy z umowy', 'W podanym polu umieść nazwę firmy, która ma podpisaną umowę z hurtownią (np. Business Dreams lub ECAT).')">
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>Nazwa</td>
                  <td>{{  warehouse.name }}</td>
                  <td>
                    <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(warehouse, 'name', 'Nazwa', 'STRING', 'Zmiana nazwy')">
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>Nazwa firmy</td>
                  <td>{{  warehouse.companyName }}</td>
                  <td>
                    <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(warehouse, 'companyName', 'Nazwa firmy', 'STRING', 'Zmiana nazwy firmy')">
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>Strona WWW Hurtowni</td>
                  <td><a :href="`//${warehouse.website}`" target="_blank">{{ warehouse.website }}</a></td>
                  <td>
                    <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(warehouse, 'website', 'Strona WWW Hurtowni', 'STRING', 'Zmiana Strony WWW Hurtowni')">
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>Nr Telefonu</td>
                  <td>{{ warehouse.contactPhoneNumber }}</td>
                  <td>
                    <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(warehouse, 'contactPhoneNumber', 'Nr Telefonu', 'STRING', 'Zmiana Nr telefonu')">
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>Adres E-mail</td>
                  <td>{{ warehouse.contactEmail }}</td>
                  <td>
                    <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(warehouse, 'contactEmail', 'Adres E-mail', 'STRING', 'Zmiana adresu E-mail')">
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>Dane dostępowe</td>
                  <td>
                    Login: <span @click="clipboardHelper.copy(warehouse.loginAccessData)" class="text-primary clickable-element">{{ warehouse.loginAccessData }} </span> <br />
                    Hasło: <span @click="clipboardHelper.copy(warehouse.passwordAccessData)" class="text-primary clickable-element">{{ warehouse.passwordAccessData }} </span>
                  </td>
                  <td>
                    <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(warehouse, 'accessData', 'Dane dostępowe', 'ACCESS-DATA', 'Zmiana danych dostępowych')">
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>Osoba kontaktowa</td>
                  <td>{{ warehouse.contactPerson }}</td>
                  <td>
                    <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(warehouse, 'contactPerson', 'Osoba kontaktowa', 'TEXTAREA', 'Zmiana osoby kontaktowej')">
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>Opis kosztu wysyłki</td>
                  <td>{{ warehouse.descriptionOfShippingCost }}</td>
                  <td>
                    <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(warehouse, 'descriptionOfShippingCost', null, 'TEXTAREA', 'Opis kosztu wysyłki')">
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>Zwroty</td>
                  <td>{{ warehouse.returns }}</td>
                  <td>
                    <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(warehouse, 'returns', null, 'TEXTAREA', 'Zwroty')">
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>Narzut</td>
                  <td>{{ warehouse.profitMargin }}%</td>
                  <td>
                    <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(warehouse, 'profitMargin', 'Narzut', 'DOUBLE', 'Zmiana narzutu')">
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>Branża hurtowni</td>
                  <td>
                    <p v-if="industry">{{ industry.name }}</p>
                  </td>
                  <td>
                    <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(warehouse, 'industryId', null, 'WAREHOUSE-INDUSTRY', 'Zmiana branży hurtowni')">
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>Rynek hurtowni</td>
                  <td>
                    <p v-if="market">
                      <img :src="market.imageUrl" alt="Icon" style="max-width: 24px; max-height: 24px;" />
                      {{ $t(market.i18nTranslation) }}
                    </p>
                  </td>
                  <td>
                    <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(warehouse, 'marketId', null, 'WAREHOUSE-MARKET', 'Zmiana rynku hurtowni')">
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>Uwagi</td>
                  <td>
                    <div v-html="warehouse.note"></div>
                  </td>
                  <td>
                    <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(warehouse, 'note', null, 'CKEDITOR', 'Uwagi')">
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>Czas dostawy</td>
                  <td>
                    <template v-if="warehouse.deliveryTime && warehouse.deliveryUnit">
                      <div :set="deliveryUnit = getDeliveryUnit(warehouse.deliveryUnit)">
                        <template v-if="deliveryUnit">
                          {{ warehouse.deliveryTime }} {{ deliveryUnit.name }}
                        </template>
                      </div>
                    </template>
                    <template v-else>
                      Nieustalony
                    </template>
                  </td>
                  <td>
                    <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(warehouse, '', 'Czas dostawy', 'DELIVERY_TIME', 'Zmiana czasu dostawy')">
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>

<!--                <tr>-->
<!--                  <td>Dodatek do ceny netto</td>-->
<!--                  <td>TODO</td>-->
<!--                  <td>TODO</td>-->
<!--                </tr>-->

                <tr>
                  <td>Limit pobierania obrazków na sekundę (0 = brak limitu)</td>
                  <td>{{ warehouse.imageDownloadRateLimitPerSecond }}</td>
                  <td>
                    <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(warehouse, 'imageDownloadRateLimitPerSecond', 'Limit pobierania obrazków na sekundę (0 = brak limitu)', 'INT', 'Zmiana limitu pobierania obrazków')">
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>Pliki parsera (kliknięcie w link otwiera w nowej karcie)</td>
                  <td>
                    <template v-if="parsers">
                      <ul>
                        <li v-for="(parser, index) in parsers" :key="index">
                          <a :href="parser.xmlUrl" target="_blank">{{ parser.xmlUrl }}</a>
                        </li>
                      </ul>
                    </template>
                  </td>
                  <td></td>
                </tr>

                <tr v-if="warehouse.deleteReason">
                  <td class="text-danger">Powód usunięcia hurtowni</td>
                  <td>{{  warehouse.deleteReason }}</td>
                  <td />
                </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

        <div class="col-12 col-lg-4">
          <div class="card">
            <div class="card-body">
              <h3>Procedura zamawiania</h3>

              <div v-html="warehouse.orderProcedure" />

              <div class="float-right py-3">
                <a class="btn btn-success" @click="openEditElementInTableModal(warehouse, 'orderProcedure', 'Procedura zamawiania', 'CKEDITOR', 'Zmiana procedury zamawiania')">Edytuj procedurę zamawiania</a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <a class="btn btn-primary" @click="openEditElementInTableModal(warehouse, 'statusId', 'Status hurtowni', 'WAREHOUSE-STATUS', 'Zmiana statusu hurtowni')">Zmiana statusu hurtowni</a>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-lg-3">
                  <warehouse-carrier-table title="Cena dostaw hurtownia" carrier-type="WHOLESALER" :warehouse="warehouse" :market="market" :carrier-mappings="carrierMappings" :ref="setRef('warehouse_carrier_table')" :key="`warehouse_carrier_table-${reloadTables}`" />
                </div>

                <div class="col-12 col-lg-3">
                  <warehouse-carrier-table title="Cena dostaw ecat (priorytet)" carrier-type="ECAT" :warehouse="warehouse" :market="market" :carrier-mappings="carrierMappings" :ref="setRef('ecat_carrier_table')" :key="`ecat_carrier_table-${reloadTables}`" />
                </div>

                <div class="col-12 col-lg-3">
                  <warehouse-carrier-table title="Cena dostaw ecat smart (priorytet)" carrier-type="ECAT_ALLEGRO_SMART" :warehouse="warehouse" :market="market" :carrier-mappings="carrierMappings" :ref="setRef('ecat_allegro_smart_carrier_table')" :key="`ecat_allegro_smart_carrier_table-${reloadTables}`" />
                </div>

                <div class="col-12 col-lg-3">
                  <warehouse-carrier-table title="Gabaryty" carrier-type="OVERSIZE" :warehouse="warehouse" :market="market" :carrier-mappings="carrierMappings" :ref="setRef('oversize_carrier_table')" :key="`oversize_carrier_table-${reloadTables}`" />
                </div>
              </div>

              <div class="text-center">
                <b-button variant="success" :disabled="!shouldRenderCommitChanges()" @click="commitChanges">Zatwierdź zmiany</b-button>
              </div>
            </div>
          </div>
        </div>

      </template>
      <template v-else>
        <div class="text-center py-3">
          <h5>{{ $t('message.loading') }}</h5>
          <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
        </div>
      </template>
    </div>

    <b-modal
        centered
        v-model="modals.editElementInTable.visibleModal"
        hide-footer
        :title="`${modals.editElementInTable.title}`"
        title-class="font-18"
        @esc="hideEditElementInTableModal"
        @hide="hideEditElementInTableModal">

      <p v-if="modals.editElementInTable.note">{{ modals.editElementInTable.note }}</p>

      <template v-if="modals.editElementInTable.type === 'INT'">
        <label v-if="modals.editElementInTable.elementName">{{ modals.editElementInTable.elementName }}</label>
        <input v-model.number="modals.editElementInTable.item[modals.editElementInTable.element]" type="number" class="form-control" />
      </template>
      <template v-else-if="modals.editElementInTable.type === 'DOUBLE'">
        <label v-if="modals.editElementInTable.elementName">{{ modals.editElementInTable.elementName }}</label>
        <input v-model.number="modals.editElementInTable.item[modals.editElementInTable.element]" type="number" class="form-control custom-number-with-gap" step="0.1" />
      </template>
      <template v-else-if="modals.editElementInTable.type === 'STRING'">
        <label v-if="modals.editElementInTable.elementName">{{ modals.editElementInTable.elementName }}</label>
        <input v-model="modals.editElementInTable.item[modals.editElementInTable.element]" type="text" class="form-control" />
      </template>
      <template v-else-if="modals.editElementInTable.type === 'TEXTAREA'">
        <label v-if="modals.editElementInTable.elementName">{{ modals.editElementInTable.elementName }}</label>
        <textarea v-model="modals.editElementInTable.item[modals.editElementInTable.element]" type="text" class="form-control" style="min-height: 150px !important;" />
      </template>
      <template v-else-if="modals.editElementInTable.type === 'CKEDITOR'">
        <label v-if="modals.editElementInTable.elementName">{{ modals.editElementInTable.elementName }}</label>
        <ckeditor v-model="modals.editElementInTable.item[modals.editElementInTable.element]" :editor="editor"></ckeditor>
      </template>
      <template v-else-if="modals.editElementInTable.type === 'BOOLEAN'">
        <div class="form-group">
          <div class="custom-control custom-checkbox mb-3">
            <input v-model="modals.editElementInTable.item[modals.editElementInTable.element]" type="checkbox" class="custom-control-input" :id="`boolean-${modals.editElementInTable.element}`" />
            <label class="custom-control-label" :for="`boolean-${modals.editElementInTable.element}`">{{ modals.editElementInTable.elementName }}</label>
          </div>
        </div>
      </template>
      <template v-else-if="modals.editElementInTable.type === 'WAREHOUSE-STATUS'">
        <label v-if="modals.editElementInTable.elementName">{{ modals.editElementInTable.elementName }}</label>

        <ecat-multiselect @change="value => modals.editElementInTable.item[modals.editElementInTable.element] = value"
                          placeholder="Wybierz nowy status hurtowni"
                          load-url="/warehouse/status/list/pagination"
                          query-url="/warehouse/status/by-name"
                          save-id="id" view-id="name"
                          list-name="warehouseStatuses"
                          param="name">
        </ecat-multiselect>
      </template>
      <template v-else-if="modals.editElementInTable.type === 'WAREHOUSE-INDUSTRY'">
        <label v-if="modals.editElementInTable.elementName">{{ modals.editElementInTable.elementName }}</label>

        <ecat-multiselect @change="value => modals.editElementInTable.item[modals.editElementInTable.element] = value"
                          :can-unselect="true"
                          save-id="id"
                          view-id="name"
                          :label="$t('message.industry')"
                          placeholder="Wybierz nową branże"
                          load-url="/industry/list/pagination"
                          query-url="/industry/by-name"
                          list-name="industries"
                          param="name">
        </ecat-multiselect>
      </template>
      <template v-else-if="modals.editElementInTable.type === 'ACCESS-DATA'">
        <div class="form-group">
          <label v-if="modals.editElementInTable.elementName">Login</label>
          <input v-model="modals.editElementInTable.item.loginAccessData" class="form-control" />
        </div>

        <div class="form-group">
          <label v-if="modals.editElementInTable.elementName">Hasło</label>
          <input v-model="modals.editElementInTable.item.passwordAccessData" class="form-control" />
        </div>
      </template>
      <template v-else-if="modals.editElementInTable.type === 'WAREHOUSE-MARKET'">
        <label v-if="modals.editElementInTable.elementName">{{ modals.editElementInTable.elementName }}</label>

        <ecat-multiselect
            @change="value => modals.editElementInTable.item[modals.editElementInTable.element] = value"
            :can-unselect="true"
            :set-value="warehouse.marketId"
            save-id="id"
            image-id="imageUrl"
            :custom-label="value => $t(value.i18nTranslation)"
            label="Rynek"
            fetch-one-url="/market"
            load-url="/market/pagination"
            list-name="markets"
            param="name"
        />
      </template>
      <template v-else-if="modals.editElementInTable.type === 'DELIVERY_TIME'">
        <div class="form-group">
          <label>Czas trwania dostawy</label>
          <input v-model="modals.editElementInTable.item.deliveryTime" type="number" class="form-control" />
        </div>

        <div class="form-group">
          <label>Jednostka</label>
          <select v-model="modals.editElementInTable.item.deliveryUnit" class="custom-select">
            <option v-for="(unit, index) in deliveryUnits" :key="index" :value="unit.deliveryUnit" >{{ unit.name }}</option>
          </select>
        </div>
      </template>

      <div class="text-center">
        <b-button class="mx-2" variant="success" @click="saveWarehouse(hideEditElementInTableModal, true)">
          Zapisz
        </b-button>

        <b-button variant="danger" class="my-3" @click="hideEditElementInTableModal">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>
  </Layout>
</template>